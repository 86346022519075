import {Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/24/outline'

export default function Imprint() {
    const [open, setOpen] = useState(false)

    const cancelButtonRef = useRef(null)

    return (
        <div onClick={() => {setOpen(!open)}}>Imprint
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-black px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <div>
                                        <div className="mt-3 text-left sm:mt-5">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-brand-light-gray">
                                                Imprint
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <div className="text-sm text-brand-light-gray">
                                                    <div className="entry clr" itemProp="text">
                                                        <p className={"pt-2"}>Gravity Technologies AG<br/>
                                                            Chamerstrasse 172<br/>
                                                            6300 Zug<br/>
                                                            Switzerland</p>
                                                        <br/>
                                                        <span className={"text-lg mt-4 pt-4"}>Contact</span>
                                                        <p>Phone: +41 (0)78 212 12 40<br/>
                                                            E-mail: info@gty.ag</p>
                                                        <br/>
                                                        <span className={"text-lg mt-4 pt-4"}>VAT ID</span>
                                                        <p>Sales tax identification number according to Sect. 27 a of
                                                            the Sales Tax Law:<br/>
                                                            CHE-351.762.501</p>
                                                        <br/>
                                                        <span
                                                            className={"text-lg mt-4 pt-4"}>EU dispute resolution</span>
                                                        <p>The European Commission provides a platform for online
                                                            dispute resolution (ODR): <a
                                                                href="https://ec.europa.eu/consumers/odr/"
                                                                target="_blank"
                                                                rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/>
                                                            Our e-mail address can be found above in the site
                                                            notice.</p>
                                                        <h3>Dispute resolution proceedings in front of a consumer
                                                            arbitration board</h3>
                                                        <p>We are not willing or obliged to participate in dispute
                                                            resolution proceedings in front of a consumer arbitration
                                                            board.</p>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="flex items-center justify-center h-12 lg:h-16 w-32 lg:w-40 bg-brand-yellow border-2 border-brand-yellow hover:text-brand-light-gray hover:bg-transparent transition-all ease-in-out duration-300"
                                            onClick={() => setOpen(false)}
                                        >
                                            Ok, thanks
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}
